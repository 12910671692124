import { useParams } from "react-router-dom";
import "../Modal.css";
import { useEffect, useRef, useState, useCallback } from "react";
import Media from "../Media.js";
import { Container } from "react-bootstrap";
import Footer from "../Footer.js";
import { Helmet } from "react-helmet-async";

// const project_text_file = "/assets/text/project_info.json";


function ProjectPage({projectarray, projectsloaded}) {

  const { name } = useParams();
  
  const projectname = useRef();
  const projectindex = useRef(0);

  const fetchDbData = (projectarray, projectShortName) => {
    let index = projectarray.current.findIndex(o => o.filename === projectShortName);
    // console.log('here', projectarray.current[0].filename)
    
    projectindex.current = index;
    // console.log(projectarray.current, index, projectShortName)
    projectname.current = projectarray.current[index].name
  }


  if (projectsloaded) {
    fetchDbData(projectarray, name);
  }

  return (
    <>
      <div style={{pointerEvents:"auto"}}>
        <Container className="pt-5">
        <div></div>
          <h2 className="mt-5 pt-5" id="example-custom-modal-styling-title" style={{color:"white"}}>
          {projectname.current}
        </h2>
        </Container>
        <Media projectarray={projectarray} 
                projectindex={projectindex.current} 
                projectsloaded={projectsloaded}
                /> 
      </div>
      <Footer/>
    </>
  );
}

export default ProjectPage;