import Modal from 'react-bootstrap/Modal';
import websiteBranding from "../images/branding/SVG/logo_alpha_crop.svg";
import "./Modal.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect} from "react";
import Media from "./Media.js";
// import { Helmet } from 'react-helmet-async';

function ModalPage({showModal, setShowModal, projectId, projectarray}) {
  
  const navigate = useNavigate();
  const { name } = useParams();

  console.log(projectarray,projectId, useParams())

  // Find out if page refreshed - if it has navigate to Project Page
  useEffect(() => {
    // Check if the page is being refreshed
    const isRefreshed = window.performance.getEntriesByType("navigation")[0].type;
    if (name !== undefined && isRefreshed === "reload") {
      navigate('/projects/'+name);
    } 
    }, [name, navigate]);
 
  // close the modal - set showmodal false and navigate with url
  const closeModal = () => {
    console.log('is closing')
    const navHome = () => navigate('/');
    setShowModal(false);
    navHome();
    // Turn these back on
    // linkslength.current = 0;
    // rolelength.current = 0;
  };

  return (
    <>
      {/* <Helmet>
        <title>{"Project - " + projectarray.current[projectId].name}</title>
        <meta name="description" content="Find out more about Paul Trickett and how to contact him."/>
        <link rel="canonical" href={"projects/"+projectarray.current[projectId].filename}/>
      </Helmet> */}
      <Modal
          show={showModal}
          onHide={closeModal}
          fullscreen={true}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
        <Modal.Header closeButton style={{backgroundColor:"black"}}>
          <img
                  src={websiteBranding}
                  width="100"
                  height="60"
                  className="d-inline-block align-top"
                  alt="Paul Trickett Animation Logo"
              />
          <Modal.Title id="example-custom-modal-styling-title">
          {projectarray.current[projectId].name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="container-fluid" style={{backgroundColor:"black"}}>
          <Media projectarray={projectarray} 
                  projectindex={projectId} 
                  />
        </Modal.Body>
      </Modal>
    </>      
          
  );
}


export default ModalPage;        
          
