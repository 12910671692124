import http from "../http-common";


const getAll = () => {
    return http.get("/projects");
  };

  const get = id => {
    return http.get(`/projects/${id}`);
  };

//   create(data) {
//     return http.post("/tutorials", data);
//   }

//   update(id, data) {
//     return http.put(`/tutorials/${id}`, data);
//   }

//   delete(id) {
//     return http.delete(`/tutorials/${id}`);
//   }

//   deleteAll() {
//     return http.delete(`/tutorials`);
//   }

const findByTitle = title => {
    return http.get(`/projects?title=${title}`);
  };


const ProjectService = {
    getAll,
    get,
    findByTitle
};

export default ProjectService;
