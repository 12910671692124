import "../../sass/main.scss";
import Container from "react-bootstrap/Container";
import "./Showreel.css";
import { Helmet } from "react-helmet-async";

function Showreel() {
 return (

  <div>
    <Helmet>
        <title>Showreel | Paul Trickett Animation</title>
        <meta name="description" content="View Paul Trickett's latest showreel on this page."/>
        <link rel="canonical" href="/showreel"/>
    </Helmet>
   <Container className={["my-5 py-2"]}></Container>
   <Container>
    <div className="embed-responsive embed-responsive-16by9 text-center pt-5 tv-wrapper">
    <iframe src="https://player.vimeo.com/video/198853520?h=c18d8fdfc0" 
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
            style={{background:"rgb(0,0,0)"}}/>
    </div>
   </Container>
  </div>


 );

}

export default Showreel;