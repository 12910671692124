import axios from "axios";

export default axios.create({

  baseURL: "https://paultrickettanimation.co.uk/api",
  headers: {
    "Content-type": "application/json",
    // "Access-Control-Allow-Headers":"access-control-allow-methods,access-control-allow-origin",
    // "Access-Control-Allow-Origin": "https://paultrickettanimation.co.uk",
    // "Access-Control-Allow-Methods": "GET, OPTIONS"
  }
});
