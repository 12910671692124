import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import "./Portfolio.css";
import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

const imagepath = "/assets/portfolio/";
const urlpath = "/projects/"

function Portfolio({setShowModal, setProjectId, projectarray, categoryarray, location, projectsloaded}) {

  const [categoryselect, setCategorySelect] = useState("cat_all");
  const count = useRef(0);

  function ModalHandler(event) {
      console.log("event",event.target.getAttribute("item-id"))
      setProjectId(event.target.getAttribute("item-id"));
      setShowModal(true);
      console.log("running modal", event.target.getAttribute("item-id"))
      return
  
  }

  //the following handles the categories on the front page
  //this bit gets the selection of the nav tab
  function CategoryHandler(event) {
    setCategorySelect(event.target.getAttribute("data-rr-ui-event-key"));
  }

  const firstUpdate = useRef(true);
  const len = projectarray.current.length;

  // INIT ANIMATION - setup array
  const StartAnimation = (projectsloaded, len) => {

    const [projectstates, setProjectStates] = useState([]);
    const [startupfinished, setStartupFinished] = useState(false)
    const arr = Array(len).fill(false);

    // this is the iterator function to make the projects come on one by one
    const IterateGrid = useCallback((i, arr, setProjectStates) => {
      const Iterate = async (i, arr) => {
        var iter = await i;
        // console.log('iteration: ', iter);
        arr[iter] = true;
        // console.log('this',arr);

        // CHATGPT description of how this line works:
        // 1. (prevStates) =>: This part defines an arrow function that takes the previous state as 
        // an argument. In this case, it's often named prevStates but you can choose any valid 
        // variable name.

        // 2. [...prevStates, true]: Inside the arrow function, it creates a new array by spreading 
        // the elements of the previous state (prevStates) and appending true at the end.
        setProjectStates((prevStates) => [...prevStates, true]);

        if (i === arr.length-1){
          setStartupFinished(true);
          // console.log('finished', startupfinished, i, arr)
        }
        return arr;
      }
      var timeout = setTimeout(function() {Iterate(i, arr)}, 150*i);      
      return timeout;
    }, []);


    useEffect(() => {
      
      if (projectsloaded && firstUpdate && count.current === 0) {    
        count.current = 1;
        firstUpdate.current = false;
        for(let i=0; i < arr.length; i++) {
          IterateGrid(i, arr, setProjectStates);
        };

    }},[projectstates, projectsloaded, len, startupfinished, IterateGrid, arr]);

    // START OF CATEGORISING PROJECTS
    const SortCats = (startupfinished, categoryselect) =>{

      useEffect(() => {
        var arr = Array(len).fill(true);

        if (startupfinished) {
          // console.log('startup moved on', categoryarray)
          for(var i=0; i < categoryarray.current.length; i++) {
            //if the index of categoryselect is more than -1 it exists in array
            if (categoryarray.current[i].indexOf(categoryselect) > -1) {
              // console.log("value in here");
              arr[i] = true;
            } else {
              // console.log(arr);
              arr[i] = false;
              }
            }
            //set projectstates
            setProjectStates(arr)
          }
        },[startupfinished, categoryselect])
    };
  
    SortCats(startupfinished, categoryselect);

    return projectstates
  };
    
  const projectstates = StartAnimation(projectsloaded, len);

  return (

        <Container className={["pt-5", "pb-5"]}>
          <Nav variant="underline" defaultActiveKey="cat_all" className="mb-4 justify-content-end">
            <Nav.Item>
              <Nav.Link eventKey="cat_all" onClick={CategoryHandler}>All</Nav.Link>
            </Nav.Item>
            <h2>/</h2>
            <Nav.Item>
              <Nav.Link eventKey="cat_film" onClick={CategoryHandler}>Animation</Nav.Link>
            </Nav.Item>
            <h2>/</h2>
            <Nav.Item>
              <Nav.Link eventKey="cat_performance" onClick={CategoryHandler}>Performance</Nav.Link>
            </Nav.Item>
            <h2>/</h2>
            <Nav.Item>
              <Nav.Link eventKey="cat_installation" onClick={CategoryHandler}>Installation</Nav.Link>
            </Nav.Item>
          </Nav>
          <Row xs={2} md={4} className="g-2">
          {Array.from({ length: projectarray.current.length }).map((_, idx) => (
          <Col key={idx}>
              <Card bg={"black"}>    
              {projectstates[idx] ? 
              // ON
              <><Image state={projectstates[idx].toString()} 
                      src={imagepath.concat(projectarray.current[idx].filename) + ".jpg"} 
                      rounded 
                      fluid 
                      style={{transition: ".6s ease-in-out"}}
                      alt={projectarray.current[idx].name}
                      />
                <Link className="frontpage-job"
                  to={urlpath.concat(projectarray.current[idx].filename)}
                  state={{ previousLocation: location }}
                > 
                <Card.ImgOverlay style={{ background: 'rgba(0, 0, 0, 1)' }} item-id={idx} onClick={ModalHandler}>
                  <Card.Title>{projectarray.current[idx].name}</Card.Title>
                </Card.ImgOverlay>
                </Link></>
              :
              // OFF
              <><Image src={imagepath.concat(projectarray.current[idx].filename) + ".jpg"} rounded fluid style={{opacity:0.1, background:'rgba(0, 0, 0, 1)', transition: ".6s ease-in-out"}}/></>
              }    
              </Card>

          </Col>
          ))}
          </Row>
        </Container>
        
    );
}



export default Portfolio;