import "../../sass/main.scss";
import Portfolio from '../Portfolio';
import Container from "react-bootstrap/Container";
import Footer from "../Footer";
import { Helmet } from "react-helmet-async";

function Home({projectarray, categoryarray, location, projectsloaded, setShowModal, setProjectId}) {

  return (
    <>
      <Helmet>
        <title>Home | Paul Trickett Animation</title>
        <meta name="description" content="Paul Trickett is an artist and creative technologist living and working in Newcastle-Upon-Tyne. He has skills in the fields of 3D and 2D animation, VFX, and immersive media, installation and performance."/>
        <link rel="canonical" href="https://paultrickettanimation.co.uk"/>
      </Helmet>
      <div className="container-fluid">
        <Container className="px-3">
          <Container className="my-5 py-1"></Container>
          <Portfolio setShowModal={setShowModal} 
                            setProjectId={setProjectId} 
                            projectarray={projectarray} 
                            categoryarray={categoryarray} 
                            location={location} 
                            projectsloaded={projectsloaded}
                            />
        </Container>
      </div>
      <Footer/>
    </>

  );
}

export default Home;