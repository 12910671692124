import React from 'react';
import "./Navbar.css";
import Nav from 'react-bootstrap/Nav';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
// import websiteBranding from "../images/branding/SVG/horizontal_stack_alpha_crop_v4.svg";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Col } from 'react-bootstrap';
import { LogoAnimated1Simplified } from '../images/svg-icons/src';
import { LogoText1 } from '../images/svg-images/src';

function WebsiteNavbar() {
  return (
  <>
    <Navbar fixed='top' key={"md"} expand={"md"} style={{backgroundColor: "black", height: "90px", zIndex: 1}}>
      <Container>
        <Col className="d-flex align-items-center">
          <Navbar.Brand href="/" className={["pt-1", "pb-1"]}>
            <Col xs="auto" className='logo mt-auto'>              
              <LogoAnimated1Simplified className="" title='animated_logo' style={{fontSize:'4rem'}}/>
              <LogoText1 className="" title="svg-text" 
                          style={{fontSize:'10rem', maxWidth: '500px', transform: 'scale(3.65) translateX(50%)'}}
                          />
            </Col> 
          </Navbar.Brand>
        </Col>
        <Col className='col-3'>
          <Container className="justify-content-end" style={{display:"grid", color:'white'}}>
            <Nav>
            <Navbar.Toggle className="justify-content-center flex" 
                            aria-controls={`offcanvasNavbar-expand-md`} 
                            style={{width:"60px", color:'white', fontSize:"1.75rem", borderWidth: "1rem", border:"darkgrey"}}
                            />
              <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-md`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                  placement="end"
                >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 ps-3">            
                    <Nav.Link href="/showreel">Showreel</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                  </Nav>
                </Offcanvas.Body>              
              </Navbar.Offcanvas>                   
            </Nav>
          </Container>
        </Col>
      </Container>
    </Navbar>
  </>
  );
}

export default WebsiteNavbar;


