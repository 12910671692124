import "../../sass/main.scss";
// import "./Contact.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { useEffect, useState} from "react";
import ContactForm from "../Form";
import Footer from "../Footer";
import { Helmet } from "react-helmet-async";

function Contact() {
  
  const contact_text_file = "/assets/text/contact_info.json";
  const [contactInfo, setContactInfo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      var res = await fetch(contact_text_file);
      var text = await res.json();
      text = text["contact"][0];
      setContactInfo(text)
     }
     fetchData();
  }, []);


  return (
    <>
      <Helmet>
        <title>Contact | Paul Trickett Animation</title>
        <meta name="description" content="Find out more about Paul Trickett and how to contact him."/>
        <link rel="canonical" href="/contact"/>
      </Helmet>
      <div>
      <Container >
        <Container className="my-5 py-2" ></Container>
        <Row xs={1} md={2} className="g-4 pt-5">
          <Col className="pb-4" style={{display:"flex", justifyContent:"center"}}>
            <Col md={10}>
              <Card style={{ background:"rgba(255,255,255,0)" }}>
              <Container className="my-5" style={{textAlign:"center"}}>
                <Image src={"assets/contact/me_500x600.jpg"} 
                    style={{width:350, borderColor: "grey", borderWidth:".1rem", borderStyle:"ridge"}} 
                    rounded 
                    fluid />
              </Container>
              <Container className="mx-0 px-0" style={{color:"white", borderBottom: ".1rem ridge gray"}}>
                  <h5 className="px-3" style={{color:"white"}}>
                    Contact
                    </h5>
                </Container>
                <Container className="px-3">
                  <ContactForm></ContactForm>
                </Container>
              </Card> 
            </Col> 
          </Col>
          <Col>
            <Card className="py-2" style={{ background:"rgba(255,255,255,0)" }}>
              <Card.Header as="h5" style={{color:"white", borderBottom: ".1rem ridge gray"}}>
              About Me
              </Card.Header>
              <Card.Body className="px-0 mx-0">
                <Container className="px-3">
                <Card.Text className="pb-3" style={{whiteSpace: 'pre-wrap', color:"white"}}>{contactInfo}</Card.Text>
                </Container>
              </Card.Body>
            </Card>
          </Col>                   
        </Row>
      </Container>
      </div>
      <Footer/>
    </>

  );



}

export default Contact;