import Container from 'react-bootstrap/Container';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import Nav from "react-bootstrap/Nav";
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import { useRef } from 'react';

const videoplaceholderpath= "/assets/video_placeholders/"
const project_text_file = "/assets/text/project_info.json";

function Media({projectarray, projectindex, projectsloaded}) {
    
    const videoUrl = useRef();
    
    const projectinfo = useRef();
    const projectlinks = useRef([]);
    const projectrole = useRef([]);
    const rolelength = useRef(0);
    const linkslength = useRef(0);
    const shortinfo = useRef();

    const [mediaLoaded, setMediaLoaded] = useState(false);
    const [infoLoaded, setInfoLoaded] = useState(false);

    // const mediaLoaded = useRef(false);
    // const infoLoaded = useRef(false);
    // const [pageLoaded, setPageLoaded] = useState(false);

    // console.log('index', projectindex, projectarray)
    // if (projectarray.current.length > 1) {
    //     let project = projectarray.current[projectindex];
    //     console.log(project)
    //     // projectinfo.current = project.description;
    //     // projectrole.current = project.role;
    //     // projectlinks.current = project.links;
    //     // linkslength.current = projectlinks.current.length
    //     // rolelength.current = projectrole.current.length
    // }

    useEffect(() =>{

        const fetchJSONInfo = async () => {
            let res = await fetch(project_text_file);
            let json = await res.json();
            let project = json[projectarray.current[projectindex].filename];
            console.log("index", projectindex)
            
            projectinfo.current = project.description;
            shortinfo.current = project.short;
            projectrole.current = project.role;
            projectlinks.current = project.links;
            linkslength.current = projectlinks.current.length
            rolelength.current = projectrole.current.length
    
            setInfoLoaded(true);
            // infoLoaded.current = true;        
        }

        const fetchMainMedia = () => {
            if (projectarray.current[projectindex].video === null) {
                let filename = projectarray.current[projectindex].filename;
                let imagepath = videoplaceholderpath+filename+".jpg";
                // console.log(videoplaceholderpath+filename);
                videoUrl.current = imagepath;
            } else {
                // setVideoUrl(projectarray.current[projectindex].video)
                videoUrl.current = projectarray.current[projectindex].video;
                // console.log(videoUrl)
            }
            setMediaLoaded(true);
            // mediaLoaded.current = true;
        }

        if (projectarray.current.length > 1) {
            fetchMainMedia();
            fetchJSONInfo();
        }
   
    },[projectarray, projectindex]);


    // useEffect (() => {

    //     console.log(mediaLoaded.current, infoLoaded.current)

    //     if (mediaLoaded.current & infoLoaded.current) {
    //         setPageLoaded(true);
    //         console.log('getting here too')
    //     }
    // },[infoLoaded, mediaLoaded])



    // I think take this out of the useeffect one rendering works properly
    // useEffect(() => {
    //     if (projectarray[projectindex.current].video === null) {
    //         let filename = projectarray[projectindex.current].filename;
    //         let imagepath = videoplaceholderpath+filename+".jpg";
    //         console.log(videoplaceholderpath+filename);
    //         setVideoUrl(imagepath)
    //     } else {
    //         // setVideoUrl(projectarray[projectindex].video)
    //         setVideoUrl(projectarray[projectindex.current].video);
    //         console.log(videoUrl)
    // }
    // },[projectarray, projectindex, projectsloaded, videoUrl])

    return (
        <>
            <Helmet>
                <title>{"Project - " + projectarray.current[projectindex].name}</title>
                <meta name="description" content={shortinfo.current}/>
                <link rel="canonical" href={"https://paultrickettanimation.co.uk/projects/" + projectarray.current[projectindex].filename}/>
            </Helmet>
        <Container>
          <div className="embed-responsive embed-responsive-16by9 text-center pt-3 tv-wrapper">
            <iframe src={videoUrl.current} 
                      allow='autoplay; encrypted-media'
                      allowFullScreen
                      title='video'
                      style={{background:"rgb(0,0,0)"}}/>
          </div>
          <div>
            <Row className="g-4 pt-4">
                <Col xs={12} md={8}>
                <Card style={{background:"rgba(255,255,255,0)"}}>
                    <Card.Body className="px-0 mx-0">
                    <Card.Title className="px-3 pb-3" style={{color:"white", borderBottom: ".1rem ridge gray"}}>
                    Project Description
                    </Card.Title>
                    <Container className="px-3">
                        <Card.Text style={{color:"rgba(var(--bs-altlight-rgb))"}}>
                        {projectinfo.current}
                        </Card.Text>
                    </Container>
                    </Card.Body>
                </Card>
                </Col>
                <Col xs={12} md={4}>
                <Card style={{ background:"rgba(255,255,255,0)"}}>
                <Card.Body className="px-0 mx-0">
                    <Card.Title className="px-3 pb-3" style={{color:"white", borderBottom: ".1rem ridge gray"}}>
                        Role
                        </Card.Title>
                        <ul style={{color:"rgba(var(--bs-altlight-rgb))"}}>
                        {Array.from({length:rolelength.current}).map((_,idx) => (
                            <li key={idx}>{projectrole.current[idx]}</li>
                            ))}
                        </ul>
                        <Container className="px-3 pb-2" style={{color:"white", borderBottom: ".1rem ridge gray"}}>
                            <Card.Title className="pt-3" style={{color:"white"}}>
                            External Links
                            </Card.Title>
                        </Container>
                        <Nav defaultActiveKey="/" className="pt-3 px-3 pb-4" >
                        {Array.from({length:linkslength.current}).map((_,idx) => (
                        <Nav.Link key={idx} href={projectlinks.current[idx].href} target="_blank" rel="noopener noreferrer">{projectlinks.current[idx].name}</Nav.Link>
                        ))}
                        </Nav>

                </Card.Body>
                </Card>
            </Col>
            </Row>
          </div>
        </Container>
      </>
    );
}

export default Media;