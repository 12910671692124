
function Footer() {

    return (
        <footer>
        <div className="container justify-content-end" style={{display:"flex"}}>
            <h6 className="text-muted">Website and images created by Paul Trickett © 2024</h6>
        </div>
        </footer>
    );
}

export default Footer;