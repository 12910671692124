import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState} from "react";
import "./Form.css";

function ContactForm() {
    const initialState = {name: "", email: "", subject: "", message: ""};
    const [eachEntry, setEachEntry] = useState(initialState);
    const {name, email, subject, message} = eachEntry;

    //handle all changes and set hooks - ...eachentry looks through each hook to set values
    const handleOnChange = e => {
        setEachEntry({...eachEntry, [e.target.name]: e.target.value});
    };

    // Determine the SEND URL based on the environment
    const sendUrl = process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_SEND_URL
        : process.env.REACT_APP_PROD_SEND_URL;

    //connect to backend to submit
    const handleSubmit = e => {
        e.preventDefault()    
        fetch(sendUrl, {
            method: "POST",
            body: JSON.stringify(eachEntry),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            (response) => (response.json())
           ).then((response)=> {    
            
        if (response.status === 'success') {
            alert("Message Sent!");
            setEachEntry({...eachEntry, [e.target.name]: initialState})//reset form
        } else if(response.status === 'fail') {
            alert("Message failed to send.")
        }
        })
    }

    return (
    <>
        <Form>

            <Container className='my-3 pt-1'></Container>

            <Container style={{justifyItems:'stretch', display:'grid'}} >
                <Form.Group className="my-1" controlId="formGridName">
                    <Form.Control onChange={handleOnChange}
                                value={name}
                                name='name'
                                type='text'
                                placeholder="Enter full name" 
                                style={{backgroundColor:"rgb(var(--bs-altlight-rgb))"}}
                                />
                </Form.Group>

                <Form.Group className="my-1" style={{display:'grid'}} controlId="formGridEmail">
                    <Form.Control onChange={handleOnChange}
                                value={email}
                                name='email'
                                type="email" 
                                placeholder="Enter email" 
                                style={{backgroundColor:"rgb(var(--bs-altlight-rgb))"}}
                                />
                </Form.Group>
            </Container>

            <Container className= "mt-3" style={{justifyItems:'stretch', display:'grid'}}>
                <Form.Group className="my-2" controlId="formGridSubject">
                    <Form.Control onChange={handleOnChange}
                                value={subject}
                                name='subject'
                                type='text'
                                placeholder="Enter subject" 
                                style={{backgroundColor:"rgb(var(--bs-altlight-rgb))"}}
                                />
                </Form.Group>
                
                <InputGroup className='mt-1'>
                    <InputGroup.Text>Message</InputGroup.Text>
                    <Form.Control onChange={handleOnChange}
                                value={message}
                                name='message'
                                type='text'
                                as="textarea" 
                                style={{ height: "100px", backgroundColor:"rgb(var(--bs-altlight-rgb))"}} 
                                aria-label="With textarea" 
                                />
                </InputGroup>
            </Container>
            
            <Container>
                <Button onClick={handleSubmit} className="mt-3 bg-altdark" style={{borderWidth:".05rem", borderColor:"grey"}}>Send</Button>
            </Container>

        </Form>
    </>
)
}

export default ContactForm;