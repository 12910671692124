import './App.css';
import WebsiteNavbar from "./components/Navbar";
import Home from "./components/pages/Home";
import Showreel from "./components/pages/Showreel";
import Contact from "./components/pages/Contact";
import ProjectPage from "./components/pages/Projectpage";
import { useState, useEffect, useRef} from "react";
import {Route, Routes, useLocation} from 'react-router-dom';
import ModalPage from './components/Modal';
import ProjectService from './services/main_projects.service';

function App() {

  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  const db_template = {name: null, year: null, filename: null, video: null , category: null};
  const [projectsloaded, setProjectsLoaded] = useState(false);
  const projectarray = useRef([Array(12).fill(db_template)]);
  const categoryarray = useRef([["none"]]);
  const [projectId, setProjectId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  console.log('app')

  const getData = async () => {
    let obj = await ProjectService.getAll();
    let array = obj["data"].reverse()
    projectarray.current = array;
    setProjectsLoaded(true);
  };

  // returns projectarray from database
  useEffect(() => {
    getData();
    console.log("getting data - render")
    }, []);
    

  // console.log(projectarray);

  //set category array from project array
  useEffect(() => {

    if (projectsloaded) {
      var arr = []
      //loops through projectarray and creates array just with categories
      for(let i = 0; i< projectarray.current.length; i++) {
        let text = projectarray.current[i].category;
        text = text.split(',');
        arr.push(text);
      }
    }
      // setCategoryArray(arr)
      categoryarray.current = arr
      // console.log(categoryarray)
  }, [projectarray, projectsloaded])

  // console.log('pl',projectarray);

  return (
    <div className='App'>
      <WebsiteNavbar />
      <div className='content'>
        <Routes location={previousLocation || location}>

          <Route path="/" element={<Home projectarray={projectarray} 
                                        categoryarray={categoryarray} 
                                        location={location} 
                                        projectsloaded={projectsloaded} 
                                        setShowModal={setShowModal}
                                        setProjectId={setProjectId}
                                        /> } />
          <Route path="/showreel" element={<Showreel />}></Route>
          <Route path="/projects/:name" element={<ProjectPage projectarray={projectarray} 
                                                              projectsloaded={projectsloaded}
                                                              />} />
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        {previousLocation && (
        <Routes>
          <Route path="/projects/:name" element={<ModalPage projectarray={projectarray}
                                                  projectId={projectId} 
                                                  showModal={showModal}
                                                  setShowModal={setShowModal}
                                                  />} />
        </Routes>
        )}
      </div>
    </div>
  );
}

export default App;
